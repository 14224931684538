
import Header from '@/components/header/index.vue'
import Footer from '@/components/footer/index.vue'
import ContactUs from '@/components/contactUs/index.vue'
import { times } from 'lodash'
import {config} from "@/utils"
export default {
    name: 'information',
    components:{
        Header,
        Footer,
        ContactUs
    },
    mounted () {
    },
    created(){
        if(this.zNum==1){
            this.daList=this.labList
        }

    },
    methods: {
        check(e){
            this.$router.push({
                path:'/details',
                query:{name:e.name,type:e.type,time:e.time,source:e.source,status:e.status}

            })

        },
        changeZ(v) {
            this.zNum = v
            if(this.zNum==1){
                this.daList=this.labList
            }
            if(this.zNum==2){
                this.daList=this.labList.filter(x=>{
                    return x.status==1 
                })

            }
            if(this.zNum==3){
                this.daList=this.labList.filter(x=>{
                    return x.status==2
                })

            }
            if(this.zNum==4){
                this.daList=this.labList.filter(x=>{
                    return x.status==3 
                })

            }
        },
    },

    data(){
        return{
            zNum:1,
            daList:[],
            labList:[
                {   
                    type:1,
                    status:1,
                    img:require('./images/s1.png'),
                    name:'《国家知识产权局 司法部关于加强新时代专利侵权纠纷行政裁决工作的意见》政策解读',
                    show:'近日，国家知识产权局、司法部联合印发《关于加强新时代专利侵权纠纷行政裁决工作的意见》（以下简称《意见》），现就《意见》解读如下。',
                    explain:' 一、制定背景',
                    time:'发布时间：2023-09-15',
                    source:'来源：国家知识产权局政务微信',
                    category:'政策解读',
                },
                {   
                    type:2,
                    status:1,
                    img:require('./images/s2.png'),
                    name:'《企业知识产权合规管理体系 要求》（GB/T 29490-2023）国家标准解读',
                    show:'由国家知识产权局组织起草、国家知识管理标准化技术委员会（SAC/TC 554）归口管理的《企业知识产权合规管理体系　要求》（GB/T 29490-2023）已于2023年8月6日由国家市场监督管理总局、国家标准化管理委员会发布，将于2024年1月1日正式实施。相较于上...',
                    explain:'',
                    time:'发布时间：2023-09-05',
                    source:'来源：国家知识产权局政务微信',
                    category:'政策解读',
                },
                {   
                    type:3,
                    status:1,
                    img:require('./images/s3.png'),
                    name:'《专利（申请）权转让合同模板及签订指引》《专利实施许可合同模板及签订指引》解读',
                    show:'为深入贯彻落实《“十四五”国家知识产权保护和运用规划》“指导规范知识产权交易，完善知识产权质押登记和转让许可备案管理制度”有关部署，提供更加规范、便利、高效的专利权转让合同登记和专利实施许可合同备案服务，指导当事人防范法律风险、维护自身合法权益，...',
                    explain:'',
                    time:'发布时间：2023-06-30',
                    source:'来源：国家知识产权局政务微信',
                    category:'政策解读',
                },
                {   
                    type:4,
                    status:1,
                    img:require('./images/s4.png'),
                    name:'《商品交易市场知识产权保护规范》国家标准解读',
                    show:'《商品交易市场知识产权保护规范》国家标准（以下简称标准）已于2022年12月30日发布，将于2023年7月1日实施。该标准结合我国商品交易市场知识产权保护的发展实际，在充分借鉴知识产权保护规范化市场建设已有经验的基础上制定形成。',
                    explain:'',
                    time:'发布时间：2023-02-17',
                    source:'来源：国家知识产权局政务微信',
                    category:'政策解读',
                },
                {   
                    type:5,
                    status:1,
                    img:require('./images/s5.png'),
                    name:'专利产品备案常见问题解答',
                    show:'一、开展专利产品备案的背景和意义是什么？',
                    explain:'答：《知识产权强国建设纲要（2021—2035年）》和《“十四五”国家知识产权保护和运用规划》对培育发展专利密集型产业，探索开展专...',
                    time:'发布时间：2023-11-25',
                    source:'来源：国家知识产权局政务微信',
                    category:'政策解读',
                },
                {   
                    type:6,
                    status:2,
                    img:require('./images/s6.png'),
                    name:'这些版权小常识你了解吗？',
                    show:'为贯彻落实党中央、国务院关于加强知识产权工作的决策部署，庆祝第20个世界知识产权日，加强版权宣传普吉，配合推动创建全国版权示范城市工作，提升我区版权管理、服务、运用水平，推动我区软件产业、文化企业高质量发展。今起天心区围绕“强化版权治理，优化版权生...',
                    explain:'',
                    time:'发布时间：2023-04-23',
                    source:'来源：澎湃政务',
                    category:'版权小知识',
                },
                {   
                    type:7,
                    status:2,
                    img:require('./images/s7.png'),
                    name:'哪些行为是侵犯著作权的行为?',
                    show:'作品的作者无论是否发表作品，作者都享有著作权的，著作权受法律保护。而在现实生活中，侵犯著作权的行为是经常发生的，侵犯著作权是侵权行为，那么著作权会的侵权行为有哪些？小编整理相关知识，希望对大家有帮助。',
                    explain:'',
                    time:'发布时间：2023-08-08',
                    source:'来源：华律网整理',
                    category:'版权小知识',
                },
                {   
                    type:8,
                    status:2,
                    img:require('./images/s8.png'),
                    name:'侵犯著作权的行为可能承担什么责任',
                    show:'侵犯著作权应当承担的责任有民事赔偿责任和行政处罚责任，如果构成犯罪的，还要承担刑事责任。需要注意的是，侵犯著作权的行为有可能涉及侵犯著作权罪。',
                    explain:'',
                    time:'发布时间：2023-05-30',
                    source:'来源：找法网官方整理',
                    category:'版权小知识',
                },
                {   
                    type:9,
                    status:2,
                    img:require('./images/s9.png'),
                    name:'我国保护著作权的法律法规主要有哪些？',
                    show:'有关知识产权的法律有：《中华人民共和国民法典》、《中华人民共和国著作权法》、《中华人民共和国商标法》、《中华人民共和国专利法》《植物新品种保护条例》、《集成电路布图设计保护条例》、《地理标志产品保护规定》等。',
                    explain:'',
                    time:'发布时间：2022-12-02',
                    source:'来源：法师兄（原110咨询网）',
                    category:'版权小知识',
                },
                {   
                    type:10,
                    status:2,
                    img:require('./images/s10.png'),
                    name:'著作权包括有哪些权利？',
                    show:'著作权包括精神权利和经济权利。精神权利是指发表权、署名权、修改权等；经济权利是指放映权、广播权、信息网络传播权、摄制权以及应当由著作权人享有的其他权利等。',
                    explain:'',
                    time:'发布时间：2021-10-22',
                    source:'来源：找法网',
                    category:'版权小知识',
                },
                {   
                    type:11,
                    status:3,
                    img:require('./images/s11.png'),
                    name:'正薪旗下“识权识美”项目获评“中国人力资源服务机构十大创新案例”！',
                    show:'2021年3月27-28日 正薪科技旗下“识权识美”项目 从众多创新案例中脱颖而出，获评“2020年度中国人力资源服务机构十大创新案例”正薪科技是广东省唯一一家获得此奖项的企业。',
                    explain:'',
                    time:'发布时间：2021-04-01',
                    source:'来源：搜狐网',
                    category:'识权动态',
                },
                {   
                    type:12,
                    status:3,
                    img:require('./images/s12.png'),
                    name:'喜讯 | “识权识美”荣膺2020年度人力资源服务平台品牌创新奖！',
                    show:'由中国贸促会商业行业委员会主办的首届全国人力资源管理（服务）创新大赛（粤港澳大湾区赛区）从3月1日开始报名，历时3个月完美收官！原创智汇（广东）信息科技有限公司在大赛中经过初审、初赛、决赛等环节，从438家企业，161位个人，34家产业园中脱颖而出获膺『2020...',
                    explain:'',
                    time:'发布时间：2021-07-13',
                    source:'来源：识权识美公众号',
                    category:'识权动态',
                },
                {   
                    type:13,
                    status:3,
                    img:require('./images/s13.png'),
                    name:'护航知识产权，方能十全十美！',
                    show:'创新是引领发展的第一动力，保护知识产权就是保护创新，识权识美是知识产权保护及交易平台。识权识美寓意十全十美，籍金秋十月十号之美，乘国乒男团十连冠之喜，让识权妹带您深度了解识权识美平台。',
                    explain:'',
                    time:'发布时间：2022-10-11',
                    source:'来源：识权识美公众号',
                    category:'识权动态',
                },
                {   
                    type:14,
                    status:3,
                    img:require('./images/s14.png'),
                    name:'世界知识产权日丨识权识美全面加强版权工作，推动构建新发展格局',
                    show:'世界知识产权日（THE WORLD INTELLECTUAL PROPERTY DAY）,由世界知识产权组织于2001年4月26日设立，并决定从2001年起将每年的4月26日定为“世界知识产权日”',
                    explain:'',
                    time:'发布时间：2021-04-26',
                    source:'来源：人是云公众号',
                    category:'识权动态',
                },
                {   
                    type:15,
                    status:3,
                    img:require('./images/s15.png'),
                    name:'一文带您深入了解识权识美~',
                    show:'原创智汇（广东）信息科技有限公司为正薪集团旗下运营文创产业类原创人才及企业交流服务平台（识权识美www.IPPT8.com）的全资控股子公司。',
                    explain:'',
                    time:'发布时间：2021-04-28',
                    source:'来源：识权识美公众号',
                    category:'识权动态',
                },
            ]

        }
        
    }
    
}